import { template as template_378366b589674882b4548842778fdf6f } from "@ember/template-compiler";
const FKText = template_378366b589674882b4548842778fdf6f(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
