import { template as template_6cc91c8f4ff9435fa1ca400946767100 } from "@ember/template-compiler";
const FKLabel = template_6cc91c8f4ff9435fa1ca400946767100(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
