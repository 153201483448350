import { template as template_bb171fbd97be4b658de5faece30bdebc } from "@ember/template-compiler";
const WelcomeHeader = template_bb171fbd97be4b658de5faece30bdebc(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
